import React from "react";
import { EMAIL_ADDRESS } from "../constants";
import CtaButton from "../images/cta-button-1.png";

const FreeEstimate = () => {
  return (
    <a href={`mailto:${EMAIL_ADDRESS}`}>
      <img
        src={CtaButton}
        alt="Get in touch"
        className="absolute left-[42%] -top-14 w-[100px]"
      />
    </a>
  );
};

export default FreeEstimate;
