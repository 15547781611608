import React from "react";

import ArchitectureIcon from "../images/service-icons/interior-design.svg";
import RenovationIcon from "../images/service-icons/building-renovation.svg";
import ConstructionIcon from "../images/service-icons/construction.svg";
import Eyebrow from "./eyebrow";
import ServiceItem from "./serviceItem";

const Services = () => {
  return (
    <div id="services">
      <div className="container mx-auto">
        <div className="flex flex-col md:gap-20 gap-10 lg:py-28 md:py-20 py-12">
          <div className="grid lg:grid-cols-12 grid-cols-1 gap-8">
            <div className="lg:col-span-8">
              <Eyebrow label="OUR SERVICES" />
              {/* <h2 className="font-display md:text-display-xl text-display-md pt-5">
                We provide the <span className="italic">best solutions</span>{" "}
                for your dream home
              </h2> */}
            </div>
          </div>
          <div className="flex lg:flex-row flex-col gap-8">
            <ServiceItem
              icon={ArchitectureIcon}
              title="Painting"
              description="Commercial and residential, interior and exterior painting and drywall. Remove old paint if any and prep the area, caulking cracks, priming, masking, making sure all furniture is covered.
               We offer free estimates!"
            />
            <ServiceItem
              icon={RenovationIcon}
              title="Flooring and Tiling"
              description="Wooden flooring and tiling (backsplashes or wall tiling). Kitchen, living room, bedrooms, basement."
            />
            <ServiceItem
              icon={ConstructionIcon}
              title="Custom Kitchen Cabinets"
              description="We offer custom cabinet creations and will help you from start to finish. We will first get measurements and design the cabinets with you, then make them with our CNC machine and paint to finish."
            />
          </div>
        </div>
      </div>
    </div>
  );
};
export default Services;
