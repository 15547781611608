import React from "react";
import { useStaticQuery, graphql } from "gatsby";
// import { GatsbyImage, getImage } from "gatsby-plugin-image";

const Hero = () => {
  // const data = useStaticQuery(graphql`
  //   {
  //     heroimage: file(relativePath: { eq: "hero-painting.jpg" }) {
  //       childImageSharp {
  //         gatsbyImageData(
  //           width: 5120
  //           placeholder: BLURRED
  //           formats: [AUTO, WEBP, AVIF]
  //         )
  //       }
  //     }
  //   }
  // `);
  return (
    <div>
      <div className="container mx-auto relative">
        <div className="flex flex-col xl:px-32 items-center text-center gap-6 py-20">
          <h1 className="font-display md:text-display-md">
            {/* Make <span className="italic">your home</span> an ode to joy */}
            A Colorado Painting Company
          </h1>
          <p className="col-span-8 md:text-body-xl text-body-lg font-light text-neutral-700 max-w-[800px]">
            Your trusted contractor for all your painting, drywall, home
            remodeling, kitchen cabinet, tiling and flooring needs. From minor
            touch-ups to complete renovations, our skilled team is dedicated to
            delivering exceptional results that exceed your expectations.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Hero;
