import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";

const customerReviews = [
  {
    quote:
      "I have worked with Aagii from Sab Contracting for 15+ years and he has always done an excellent job for myself and my clients. He is always fair in pricing, on time and very professional. 10/10 - would recommend.",
    author: "Jeff VanDerhule, Kentwood Real Estate ",
  },
  {
    quote:
      "Aagii from Sab Contracting does outstanding work in a highly professional and efficient way.  He is dependable and I have recommended him many times with only good reviews",
    author: "McNamara’s Painting ",
  },
  {
    quote:
      "Getting our finishes and painting done by Aagii was such a pleasure. He and his crew know exactly what needs to be done and how to do it right. Aagii and his crew finished our upstairs of our house remodel. So it was no question to have them back to finish our basement when it was ready. It's wonderful to find someone these days that takes pride in their work. The job was finished flawlessly and timely.",
    author: "Allen W.",
  },
  {
    quote:
      "Aagii is an expert painter. He excels at prep and detail by caulking the joints in drywall for a clean cut finish. Such a wonderful job on the priming and back rolling of the new construction. He painted our walls, trim, doors, and cabinets beautifully. You cannot pay someone enough to care as he does. Aagii's pride in his work product shines through the final result, and he is a pleasure to work with. This is the second house and third project I have used Aagii and his company for. They have done such a wonderful job every time.",
    author: "Judy W.",
  },
];

const Reviews = () => {
  return (
    <div>
      <Swiper
        modules={[Pagination]}
        pagination={{ clickable: true }}
        className="h-full bg-primary-600"
        loop={true}
      >
        {/* <Navigation /> */}
        {customerReviews.map((review) => (
          <SwiperSlide
            key={review.author}
            className="text-primary-100 p-14 !h-auto !flex justify-center items-center text-center"
          >
            <div>
              <div className="text-display-xs md:text-display-sm font-display mb-3">
                {review.quote}
              </div>
              <div>- {review.author}</div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default Reviews;
