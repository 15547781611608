import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

import Eyebrow from "./eyebrow";
import FreeEstimate from "./freeEstimate";

const About = () => {
  const data = useStaticQuery(graphql`
    {
      aboutimage: file(relativePath: { eq: "painting-man.jpg" }) {
        childImageSharp {
          gatsbyImageData(
            width: 592
            placeholder: BLURRED
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
    }
  `);
  return (
    <div id="about">
      <div className="container mx-auto">
        <div className="grid lg:grid-cols-12 grid-cols-1 lg:gap-8 gap-20 lg:py-32 py-12 items-center">
          <div className="lg:col-span-6 flex flex-col gap-6">
            <Eyebrow label="ABOUT US" />
            <p className="md:text-body-lg text-body-md font-light text-neutral-700">
              Founded in 2002, Sab Contracting is a trusted family owned
              contracting company based in Aurora, Colorado. With a strong
              reputation built on years of experience, we have successfully
              completed numerous projects in both the commercial and residential
              sectors. Our expertise lies in interior and exterior painting, and
              our dedicated team is committed to delivering exceptional results
              that enhance the beauty and value of your property.
            </p>
            <p className="md:text-body-lg text-body-md font-light text-neutral-700">
              We provide additional services that include kitchen cabinet
              remodeling, flooring, tiling, and drywall.
            </p>
          </div>
          <div className="lg:col-span-6 flex flex-col gap-8 relative">
            <GatsbyImage
              image={getImage(data.aboutimage)}
              alt="Interior Design"
            />
            {/* <img
              src={AwardBadge}
              alt="Award Badge"
              className="absolute left-[42%] -top-14"
            /> */}
            <FreeEstimate />
          </div>
        </div>
      </div>
    </div>
  );
};
export default About;
